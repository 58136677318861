.AdminDialogPaper {
  background: #f2f2f2 !important;
  border-radius: 0 !important;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  font-size: 100% !important;
}

.AdminDialogClose {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

.ItemForm {
  padding-top: 12%;
}

.ItemFormField {
  width: 100%;
  display: inline-block;
  margin-top: 2%;
}

.ItemFormFieldName {
  width: 20%;
  float: left;
  font-size: 90%;
}

.TextField {
  float: right;
  width: 80%;
  margin-bottom: 3% !important;
}

.YearField {
  width: 40%;
  position: relative;
  display: inline-flex;
}

.EraFields {
  width: 40%;
  display: inline-block;
  position: relative;
  padding-left: 10%;
}

.EraField {
  font-size: 110%;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.EraField:hover {
  text-decoration: underline;
}

.SelectedEra {
  color: black;
  text-decoration: underline;
}

.DeniedEra {
  color: gray;
}

input:disabled {
  color: rgba(0, 0, 0, 0.87) !important;
  margin-top: 4% !important;
}

.HelperText {
  color: red !important;
  margin-left: 0 !important;
  margin-top: 1% !important;
}

.Wrapper {
  width: 105px;
  margin: auto;
  position: relative;
}

.ButtonProgress {
  position: absolute;
  top: 15%;
  left: 40%;
}

.errorText {
  color: red;
  text-align: center;
}

@media all and (max-width: 600px) {
  .YearField {
    width: 80%;
  }

  .EraFields {
    width: 50%;
  }
}
