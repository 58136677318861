.Timeline {
  background-image: url(../shared/background.jpg);
  background-size: cover;
  height: 99.95vh;
  overflow: hidden;
  text-align: center;
}

.block {
  position: relative;
  width: 100%;
  height: 74%;
}

.espa-logo {
  position: absolute;
  width: 20%;
  left: 40%;
  top: 3%;
}

.intro {
  font-size: 225%;
  letter-spacing: 8px;
  font-weight: 100;
  color: white;
  opacity: 0.7;
  position: absolute;
  top: 40%;
  width: 100%;
  z-index: 1;
  display: none;
}

.cover {
  background: black;
  z-index: 100;
  height: 100%;
  width: 100%;
}

.cover-icon {
  position: absolute;
  top: 45%;
  width: 100%;
}

.rotation {
  position: absolute;
  top: 40%;
  width: 100%;
}

.rotation-icon {
  font-size: 350% !important;
}

.rotation-text {
  position: absolute;
  top: 46%;
  width: 100%;
  color: white;
  opacity: 0.6;
  font-size: 150% !important;
}

/* Top bar styling */

.menu {
  position: relative;
  height: 8%;
  background: rgba(0, 0, 0, 0.8) !important;
}

.languages {
  display: inline-block;
  width: 25%;
  color: white;
  position: absolute;
  top: 5%;
  left: 37.5%;
}

.language {
  display: inline-block;
  margin: 0 5%;
}

.language:hover {
  cursor: pointer;
  opacity: 1 !important;
  text-decoration: underline;
}

.museum-button {
  height: 5vh;
  width: 40px;
  display: inline-block;
}

.museum-button a {
  text-decoration: none !important;
}

.museum-button a span {
  opacity: 0.6;
}

.museum-button a span:hover {
  text-decoration: underline;
  opacity: 1;
}

.museum-home {
  height: 1vh;
  width: 100%;
}

.museum-logo {
  max-height: 3vh;
  margin-top: 5px;
}

.button {
  position: absolute;
  top: 33%;
  color: white;
  opacity: 0.8;
  cursor: pointer;
  font-size: 90%;
}

.button:hover {
  opacity: 1;
}

.button-left {
  left: 1%;
}

.button-right {
  right: 1%;
}

.button-arrow {
  display: inline-block;
  border: solid white;
  border-width: 0 1px 1px 0;
  margin: 0 5px -2px 5px;
  padding: calc(1px + 0.5vw);
}

.arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Side bar styling */

#side-menu {
  z-index: 5;
  position: absolute;
  top: 8%;
  left: -8%;
  width: 12%;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.4s ease;
}

#side-menu-text {
  color: white;
  width: 1px;
  word-wrap: break-word;
  position: absolute;
  top: 4%;
  left: 76%;
  cursor: pointer;
}

.side-menu-text-top {
  top: 10% !important;
}

.arrow-block {
  position: absolute;
  top: 34%;
  padding-top: 10%;
  left: 80%;
  width: 20%;
  height: 15%;
  cursor: pointer;
}

#side-menu-arrow {
  display: inline-block;
  opacity: 0.8;
  border: solid white;
  border-width: 0 1px 1px 0;
  padding: calc(1px + 0.3vw);
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: all 0.4s ease;
}

#side-menu-table {
  width: 40%;
  color: white;
  opacity: 0.8;
  cursor: pointer;
}

#side-menu-table td {
  padding: 5px 0 !important;
  font-size: 90% !important;
}

#side-menu-table tr:hover {
  background: rgba(0, 0, 0, 1);
}

.filter-icon {
  font-size: 200% !important;
}

#filters {
  position: absolute;
  top: 7%;
  left: 7%;
  z-index: 6;
  width: 35%;
  max-width: 400px;
  background: black;
  display: none;
  color: white;
  text-align: left;
  padding: 0 10px;
}

.range-filter {
  height: 13px;
  position: relative;
}

.handle {
  height: 10px;
  width: 4px;
  margin-top: 2px;
  border: white 1px solid;
  background: black;
  position: absolute;
}

#left-handle {
  left: 0;
}

#right-handle {
  left: 100%;
}

#colored-line {
  width: 98%;
  margin: 0 5px;
  position: absolute;
  top: 6px;
  border: 0;
  height: 3px;
}

.category-filter-color {
  width: 3%;
  height: 10px;
  margin-right: 2%;
  display: inline-block;
}

.category-filter {
  padding-left: 4%;
  padding-bottom: 2%;
  opacity: 0.5;
  cursor: pointer;
}

.category-filter:hover {
  opacity: 1 !important;
}

.filter-input-container {
  padding: 3px 6px;
  box-sizing: border-box;
  border: 1px solid gray;
  border-top: none;
  width: 48%;
  display: inline-block;
  margin-bottom: 3% !important;
}

.filter-input-container:focus {
  border: 1px solid white;
  border-top: none;
}

.filter-input-container span {
  cursor: pointer;
}

.time-input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  color: white;
  width: 50%;
}

.filter-input {
  background-color: transparent;
  outline: none;
  color: white;
  padding: 3px 6px;
  box-sizing: border-box;
  border: 1px solid gray;
  border-top: none;
}

.filter-input:focus {
  border: 1px solid white;
  border-top: none;
}

.keyword-input {
  width: 99%;
  margin-top: 0 !important;
}

.filter-buttons {
  width: 100%;
  text-align: center;
  margin: 8% 0 4% 0;
}

.filter-button {
  margin: 0 3% 2% 3%;
  display: inline-block;
  cursor: pointer;
}

/* Timeline styling */

#timeline {
  height: 100% !important;
}

.vis-timeline {
  border: none;
  height: 100% !important;
}

.vis-content {
  height: 100% !important;
  top: auto !important;
}

.vis-itemset {
  height: 93% !important;
  top: auto !important;
}

.vis-foreground .vis-group {
  height: 100% !important;
  background: transparent;
}

.vis-panel.vis-center {
  height: 100% !important;
}

.vis-panel.vis-bottom {
  border-top: none !important;
  background: black !important;
  padding-top: 5px;
  margin-top: 15px;
  font-weight: 100;
  z-index: 5;
}

.vis-minor {
  color: white !important;
  border-color: #595959 !important;
  font-family: highwaysanspro_light !important;
  font-size: 105%;
}

.vis-item.vis-dot {
  display: none;
}

.vis-item-content {
  position: relative;
  padding: 0 !important;
  white-space: normal !important;
  height: 100%;
  width: 100%;
}

.line {
  position: absolute;
  top: 10%;
  left: 47%;
  width: 0.09em;
  height: 100%;
  z-index: 2;
  background: white;
}

/* Item styling */

.vis-item.single-item {
  background: transparent;
  border: none;
  width: 20%;
  max-width: 320px;
  height: 100%;
  padding: 0;
}

.card-shade {
  position: relative;
  height: 67%;
  max-height: 840px;
  width: 100%;
  z-index: 2;
  opacity: 0.8;
  background: #1a1a1a;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}

.card-container {
  height: 58%;
  max-height: 700px;
  width: 100%;
  z-index: 4;
  background: #f2f2f2;
  position: absolute;
  top: 7%;
  left: -5%;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}

.card-content {
  position: absolute;
  top: 5%;
  left: -5%;
  height: 60%;
  max-height: 720px; 
  width: 100%;
  z-index: 4;
  cursor: pointer !important;
  text-align: left;
}

.card-img-container {
  width: 82%;
  height: 45%;
  z-index: 4;
  margin-left: 9%;
  background: black;
  display: inline-block;
  overflow: hidden;
}

.card-img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  cursor: pointer;
}

.card-texts {
  width: 82%;
  height: 43%;
  z-index: 4;
  margin: auto;
  padding-top: 6%;
  overflow: hidden;
  column-width: 10vw;
  -moz-column-width: 10vw;
  -webkit-column-width: 10vw;
}

.card-texts-no-image {
  width: 82%;
  height: 88%;
  z-index: 4;
  margin: auto;
  padding-top: 35%;
  padding-bottom: 5%;
  overflow: hidden;
  column-width: 10vw;
  -moz-column-width: 10vw;
  -webkit-column-width: 10vw;
}

.card-title {
  font-family: highwaysanspro_light !important;
  font-size: 120%;
  text-transform: uppercase;
  margin: 0;
  max-height: 25%;
  overflow: hidden;
  column-width: 10vw;
  -moz-column-width: 10vw;
  -webkit-column-width: 10vw;
}

.card-desc {
  margin: 0;
  font-size: 110%;
  /* overflow: hidden; */
}

.card-category {
  width: 100%;
  height: 6px;
  margin: 5% 0;
  display: inline-flex;
}

.card-arrow-container {
  height: 10%;
  width: 82%;
  margin: auto;
  position: relative;
  padding-left: 40%;
}

.card-arrow {
  width: 30%;
  position: absolute;
  left: 9%;
}

.card-more-arrow {
  display: inline-block;
  border: solid black;
  border-width: 0 1px 1px 0;
  padding: calc(1px + 0.5vw);
  margin-top: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Multiple items styling */

.vis-item.multiple-item {
  background: transparent;
  border: none;
  width: 20%;
  max-width: 320px;
  height: 100%;
  padding: 0;
}

.multiple-siema {
  height: 60% !important;
  width: 100%;
  z-index: 4;
  position: absolute;
  top: 5%;
  left: -5%;
  cursor: initial !important;
}

.multiple-slide {
  height: 42vh;
  text-align: left;
  cursor: pointer;
  position: relative;
}

.dots {
  margin-left: -10px !important;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #666666;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
}

.dot-active {
  background: white;
}

.arrow-container {
  height: 67%;
  max-height: 840px;
  width: 120%;
  position: absolute;
  top: 0;
  left: -15%;
}

.multiple-arrow {
  display: inline-block;
  position: absolute;
  bottom: 42%;
  outline: none;
  cursor: pointer;
  border: solid white;
  background: transparent;
  border-width: 0 2.5px 2.5px 0;
  padding: calc(1px + 0.4vw);
}

.multiple-prev {
  left: 2%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.multiple-next {
  right: 1%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Cluster styling */

.vis-item.cluster-item {
  background: transparent;
  border: none;
  width: 20%;
  max-width: 320px;
  height: 100%;
  padding: 0;
}

.cluster-more {
  /* content: " ";
  height: 4%;
  width: 13%;
  z-index: 3;
  position: absolute;
  background: transparent;
  border: medium solid white;
  border-width: 1px 1px 0px 0px;
  border-color: white white transparent transparent; */

  height: 30%;
  width: 150%;
  /* max-width: 180px; */
  z-index: 3;
  position: absolute;
  border: solid 2px;
  border-color: #f2f2f2 #f2f2f2 transparent transparent;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}

.more1 {
  right: 5%;
  top: 2.5%;
}

.more2 {
  right: 8.5%;
  top: 3.8%;
}

/* Bottom bar styling */

.periods-wrap {
  width: 100%;
  height: 18%;
}

.period {
  cursor: pointer;
  float: left;
  z-index: 5;
  width: 7.69%;
  height: 100%;
  color: white;
  position: relative;
  transition: all 0.4s ease;
}

.period:hover {
  z-index: 7;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}

.period-info {
  position: relative;
  width: 90%;
  height: 100%;
  margin: auto;
}

.period-name {
  width: 100%;
  overflow: hidden;
  font-weight: 600;
  word-spacing: 1000px;
  text-transform: uppercase;
  position: absolute;
  top: 17.5%;
}

.period-years {
  width: 100%;
  font-size: 85%;
  position: absolute;
  top: 67.5%;  
}

.full-width-period {
  -webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%) !important;
  clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%) !important;
}

.period-transformed-years {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  height: 100%;
  margin: auto;
  text-align: right;
  padding-bottom: 10px
}

.active-period {
  padding: 3%;
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: initial;
}

.active-period-info {
  float: left;
  width: 20%;
  height: 100%;
  font-size: 110%;
  padding-right: 2%;
  position: relative;
}

.active-period-name {
  text-transform: uppercase;
  font-weight: 600;
}

.active-period-description {
  float: left;
  width: 79%;
  height: 100%;
  font-size: 108%;
  position: relative;
  text-align: justify;
}

.active-period-description-non-focused {
  /* width: 100%;
  height: 70%;
  text-align: justify;
  overflow: hidden;
  white-space: pre-wrap; */
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-align: justify;
  white-space: pre-wrap;
}

.active-period-btn {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.active-period-btn-text {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

.focused-period {
  z-index: 7 !important;
  height: 200% !important;
  top: -100% !important;
  padding-top: 4% !important;
  transition: none !important;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%) !important;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%) !important;
}

.Snackbar {
  max-width: 240px;
  margin: auto;
}

@media (max-height: 500px) {
  .period-years {
    display: none;
  }

  .period-name {
    top: 25%;
  }

  .period-transformed-years {
    font-size: 72%;
    padding-bottom: 4px;
  }

  .active-period {
    padding: 1.5%;
  }

  .active-period-name {
    font-size: 82%;
  }

  .active-period-btn-text {
    font-size: 82%;
  }

  .arrow-block {
    top: 40%;
  }

  .museum-home {
    display: none;
  }

  .languages {
    top: 25%;
  }

  .museum-logo {
    max-height: 3vh;
  }
}

@media (max-height: 550px) {
  .card-title {
    font-size: 100%;
  }

  .multiple-title {
    font-size: 100%;
  }

  .card-texts {
    column-width: unset;
    -moz-column-width: unset;
    -webkit-column-width: unset;
  }
  
  .card-texts-no-image {
    column-width: unset;
    -moz-column-width: unset;
    -webkit-column-width: unset;
  }
  
  .card-title {
    column-width: unset;
    -moz-column-width: unset;
    -webkit-column-width: unset;
  }
}

@media (max-height: 600px) {
  .museum-home {
    font-size: 60%;
  }

  .museum-logo {
    margin: 0;
    max-height: 4vh;
  }
}

.hidden {
  display: none;
}
