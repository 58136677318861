@import "shared/HighwaySans_Pro_fam/stylesheet.css";

html, body {
  margin: 0;
  padding: 0;
  font-family: highwaysanspro_regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 86%;
}

@media (min-width: 1025px) {
  html, body {
    font-size: 92%;
  }
}

@media (min-width: 1921px) {
  html, body {
    font-size: 100%;
  }
}
