.dialog-paper {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-top: 0 !important;
  width: 70% !important;
  height: 55% !important;
  max-width: 850px !important;
  min-height: 300px !important;
  max-height: 550px !important;
  overflow-y: initial !important;
}

.dialog-shadow {
  clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
  background: #1a1a1a !important;
  width: 100% !important;
  height: 90% !important;
  margin: auto;
  margin-top: 5%;
  position: relative;
}

.dialog-arrow {
  display: inline-block;
  position: absolute;
  top: 45%;
  opacity: 0.8;
  border: solid white;
  border-width: 0 2px 2px 0;
  padding: calc(1px + 0.8vw);
  cursor: pointer;
}

.left {
  left: 2%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  right: 2%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.dialog-shape {
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  border-radius: 0;
  background: #f2f2f2;
  width: 85%;
  height: 92%;
  margin: auto;
  margin-top: 2%;
}

.dialog-content {
  width: 100%;
  height: 90%;
  position: relative;
}

.dialog-close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 3;
  cursor: pointer;
}

.right-panel {
  width: 30%;
  height: 100%;
  position: absolute;
  display: block;
  right: 0;
}

.right-panel-info {
  position: absolute;
  bottom: 0;
  padding-right: 10%;
}

.right-panel-period {
  display: none;
  font-size: 105%;
  text-transform: uppercase;
}

.right-panel-year {
  display: inline-block;
  margin-top: 10%;
  font-size: 165%;
  border-bottom: 4px solid;
  font-family: highwaysanspro_light !important;
}

.social-networks-container {
  position: absolute;
  height: 45%;
  width: 100%;
  top: 0;
}

.social-networks {
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.social-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}

.social-network-button {
  cursor: pointer;
  outline: none;
}

.social-network-button:hover {
  opacity: 0.75;
}

.left-panel {
  width: 56%;
  height: 100%;
  position: absolute;
  display: block;
  left: 8%;
}

.left-panel-title-container {
  position: absolute;
  width: 100%;
  top: 0;
}

.panel-with-image {
  height: 62%;
}

.panel-no-image {
  height: 47%;
}

.dialog-header {
  font-family: highwaysanspro_light !important;
  font-size: 120%;
  text-transform: uppercase;
  margin-bottom: 1%;
  position: absolute;
  bottom: 0;
}

.dialog-desc {
  width: 100%;
  height: 25%;
  position: absolute;
  top: 65%;
  text-align: justify;
  font-size: 110% !important;
}

.dialog-desc-no-image {
  width: 100%;
  height: 45%;
  position: absolute;
  top: 50%;
  text-align: justify;
  font-size: 110% !important;
}

.dialog-img {
  height: 40%;
  max-width: 65%;
  position: absolute;
  top: 3%;
  left: 15%;
  cursor: pointer;
}

.dialog-spinner {
  height: 30%;
  max-width: 65%;
  position: absolute;
  top: 15%;
  left: 15%;
}

.dialog-img-full-btn {
  position: absolute;
  top: 3%;
  left: 15%;
  z-index: 1;
  cursor: pointer;
}

.category-color {
  width: 98%;
  height: 4px;
  position: absolute;
  bottom: 0;
  display: inline-flex;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 750px) {
  .right-panel-year {
    margin-top: 5%;
    font-size: 140%;
  }
}
